import { getHumanizeMeasureUnit } from '../../../../../../../shared/utils/inbodyUtils';
import { EntryFieldConfig } from '../../../../../../interfaces/entityInbodyData';
import { CheckInFieldsConfig } from '../CheckInHistoryTab.settings';

export const processCheckInConfig = (
  checkInFieldsConfig: CheckInFieldsConfig,
  entryFieldConfig: EntryFieldConfig,
) => {
  const availableFields = checkInFieldsConfig.filter(
    (config) => !config.configField || entryFieldConfig[config.configField],
  );

  const updateUnits = availableFields.map((config) => {
    return {
      ...config,
      units:
        config.units || getHumanizeMeasureUnit(entryFieldConfig.measureUnit, 'short'),
    };
  });

  return updateUnits;
};
