import React from 'react';

import { useController, useFormContext } from 'react-hook-form';
import { Radio, RadioGroup } from '@mui/material';

import classes from './BackgroundSection.module.scss';
import { ModalInputBox } from '../../../../../../shared/components/modals';
import {
  SocialSharingBgOption,
  SocialSharingScanMetricsParams,
} from '../../../../../interfaces/socialSharingScanMetrics';

type BackgroundSectionProps = {
  bgOptions: SocialSharingBgOption[];
};

const BackgroundSection = ({ bgOptions }: BackgroundSectionProps) => {
  const { control } = useFormContext<SocialSharingScanMetricsParams>();

  const { field } = useController({
    control,
    name: 'bgImg',
    defaultValue: bgOptions[0].bgImgKey,
  });

  return (
    <ModalInputBox
      title="Post background"
      description="Choose one of the backgrounds offered by the company"
    >
      <RadioGroup {...field} className={classes.bgGroup}>
        {bgOptions.map((bg) => (
          <label className={classes.bgCard} key={bg.bgImgKey}>
            <img src={bg.bgImgUrl} alt="bg" className={classes.bgImg} />
            <Radio value={bg.bgImgKey} />
          </label>
        ))}
      </RadioGroup>
    </ModalInputBox>
  );
};

export { BackgroundSection };
