import {
  DefaultWorkoutMealInfo,
  MealNutrientsInfo,
  mealPlanService,
} from '../../../../../../nutrition';
import { calculateMealCalories } from '../../../../../../shared/utils/mealUtils';

import { WorkoutSettingsFormValues } from '../WorkoutSettingsStep.settings';

const getMealNutrientsInfo = (mealsInfo: DefaultWorkoutMealInfo): MealNutrientsInfo => {
  const { proteins, carbs, fats } = mealsInfo;

  return {
    proteins,
    carbs,
    fats,
    calories: calculateMealCalories({ proteins, carbs, fats }),
  };
};

export const getWorkoutSettingsFormValues =
  async (): Promise<WorkoutSettingsFormValues> => {
    const { postWorkout, preWorkout } =
      await mealPlanService.getDefaultPrePostWorkoutMeals();

    return {
      workoutMeals: {
        postWorkout: getMealNutrientsInfo(postWorkout),
        preWorkout: getMealNutrientsInfo(preWorkout),
      },
      selectActivity: { sports: [], workouts: [] },
      defaultMealsPerDay: 6,
    };
  };
