import { Option } from '../../../general-types';
import { ValueOfKey } from '../../../utils-types';
// TODO replace to inbody module
export const INBODY_MEASURE_UNIT = {
  INCHES: 'INCHES',
  CENTIMETERS: 'CENTIMETERS',
} as const;

export type InbodyMeasureUnit = ValueOfKey<typeof INBODY_MEASURE_UNIT>;

export const inbodyMeasureUnitOptions: Option<InbodyMeasureUnit>[] = [
  { label: 'Inches', value: 'INCHES' },
  { label: 'Centimeters', value: 'CENTIMETERS' },
];

export const humanizeMeasureUnit: Record<InbodyMeasureUnit, string> = {
  INCHES: 'Inches',
  CENTIMETERS: 'Centimeters',
};

export const humanizeMeasureUnitShort: Record<InbodyMeasureUnit, string> = {
  INCHES: 'in',
  CENTIMETERS: 'cm',
};
