import React from 'react';

import { useForm } from 'react-hook-form';

import {
  ModalInputBox,
  ModalInputGroup,
} from '../../../../../../shared/components/modals';
import { LbAutocomplete } from '../../../../../../shared/components/form-components';
import { useAutoSubmitForm } from '../../../../../../shared/hooks/useAutoSubmitForm';
import { useScanDataRangeOptions } from '../../../../../hooks/useScanDataRangeOptions/useScanDataRangeOptions';

type CompareDatesFormValues = {
  startScanDate: string;
  endScanDate: string;
};

type CompareDateFormProps = {
  onSubmit: (dates: CompareDatesFormValues) => void;
  scanDateTimes: string[]; // 'MM.DD.YYYY'
};

const CompareDateForm = ({ onSubmit, scanDateTimes }: CompareDateFormProps) => {
  const { control, watch, handleSubmit } = useForm<CompareDatesFormValues>();
  const { startScanDate, endScanDate } = watch();
  useAutoSubmitForm(handleSubmit(onSubmit), watch);

  const { startScanDateOptions, endScanDateOptions, getOptionLabel } =
    useScanDataRangeOptions({
      scanDateTimes,
      startScanDate,
      endScanDate,
    });

  return (
    <ModalInputBox
      title="Select Scans  dates for the period you want to compare"
      showDivider={false}
    >
      <ModalInputGroup>
        <LbAutocomplete
          control={control}
          variant="outlined"
          size="small"
          name="startScanDate"
          label="Start Scan Date"
          options={startScanDateOptions}
          getOptionLabel={getOptionLabel}
        />
        <LbAutocomplete
          control={control}
          variant="outlined"
          size="small"
          name="endScanDate"
          label="End Scan Date"
          options={endScanDateOptions}
          getOptionLabel={getOptionLabel}
        />
      </ModalInputGroup>
    </ModalInputBox>
  );
};

export { CompareDateForm };
