import React from 'react';

import { Divider, Paper } from '@mui/material';
import { useForm } from 'react-hook-form';
import ShowChartIcon from '@mui/icons-material/ShowChartRounded';
import FormatListBulletedRoundedIcon from '@mui/icons-material/FormatListBulletedRounded';

import { GetInbodyScansDataResponse } from '../../../../../interfaces/customerInbodyData';
import classes from './CheckInHistoryTab.module.scss';
import { ComboBox } from '../../../../../../shared/components/form-components';
import {
  LbTabPanel,
  LbTabs,
  LbTabsList,
  TabsConfig,
} from '../../../../../../shared/components/tabs';
import { CheckInHistoryTableView } from './CheckInHistoryTableView/CheckInHistoryTableView';
import { CheckInHistoryChartView } from './CheckInHistoryChartView/CheckInHistoryChartView';
import { EntryFieldConfig } from '../../../../../interfaces/entityInbodyData';
import { useFilterByField } from '../../../../../../shared/hooks/useFilterByField';
import { useAutoSubmitForm } from '../../../../../../shared/hooks/useAutoSubmitForm';
import { CustomerParam } from './CustomerParam/CustomerParam';
import { processCheckInConfig } from './utils/processCheckInConfig';
import { checkInFieldsConfig } from './CheckInHistoryTab.settings';
import { getScanDateOptions } from './utils/getScanDateOptions';

const CheckInViewTabs = {
  CHART: 'CHART',
  TABLE: 'TABLE',
} as const;
const { CHART, TABLE } = CheckInViewTabs;

type CheckInHistoryFormValues = {
  scanDate: string;
};

type CheckInHistoryTabProps = {
  entryFields: EntryFieldConfig;
  customerInbodyMetrics: GetInbodyScansDataResponse;
};

const CheckInHistoryTab = ({
  entryFields,
  customerInbodyMetrics,
}: CheckInHistoryTabProps) => {
  const { watch, control, handleSubmit } = useForm<CheckInHistoryFormValues>();

  const scanDateOptions = getScanDateOptions(customerInbodyMetrics.dateTimes);

  const availableFields = processCheckInConfig(checkInFieldsConfig, entryFields);

  const {
    filteredData: filteredScansInfo,
    filterValue: selectedScanTime,
    handleSetFilterValue,
  } = useFilterByField(customerInbodyMetrics.data, 'dateTimeScanOn');

  useAutoSubmitForm(
    handleSubmit(({ scanDate }) => handleSetFilterValue(scanDate)),
    watch,
  );

  const tabsConfigs: TabsConfig[] = [
    {
      label: <ShowChartIcon fontSize="small" />,
      value: CHART,
      panel: (
        <CheckInHistoryChartView
          inbodyScanMetrics={customerInbodyMetrics.data}
          availableFields={availableFields}
          selectedScanTime={selectedScanTime}
        />
      ),
    },
    {
      label: <FormatListBulletedRoundedIcon fontSize="small" />,
      value: TABLE,
      panel: (
        <CheckInHistoryTableView
          userInbodyMetricsDataByDate={filteredScansInfo}
          availableFields={availableFields}
        />
      ),
    },
  ];

  return (
    <LbTabs defaultValue={TABLE}>
      <Paper variant="box" className={classes.topBlock}>
        <div className={classes.leftItems}>
          <CustomerParam title="Height" value={`${customerInbodyMetrics.height}in`} />
          <Divider orientation="vertical" flexItem />
          <CustomerParam title="Age" value={customerInbodyMetrics.age} />
          <Divider orientation="vertical" flexItem />
          <CustomerParam title="Gender" value={customerInbodyMetrics.gender} />
          <Divider orientation="vertical" flexItem />
          <ComboBox
            className={classes.dateSelect}
            variant="outlined"
            name="scanDate"
            label="Scan Date"
            options={scanDateOptions}
            control={control}
            defaultValue=""
          />
        </div>
        <LbTabsList
          className={classes.tabsList}
          tabsConfig={tabsConfigs}
          variant="toggle"
        />
      </Paper>
      <LbTabPanel tabsConfig={tabsConfigs} />
    </LbTabs>
  );
};

export { CheckInHistoryTab };
