import { isNumber } from 'lodash';
import { getRoundedNumber } from '../../mathUtils';

type Config<T extends Record<string, any>> = {
  ignoreFields?: readonly (keyof T)[];
  digits?: number;
};

export const roundFields = <T extends Record<string, any>>(
  obj: T,
  { ignoreFields = [], digits = 1 }: Config<T> = {},
): T =>
  Object.fromEntries(
    Object.entries(obj).map(([key, val]) => {
      if (isNumber(val) && !ignoreFields.includes(key)) {
        return [key, getRoundedNumber(val, digits)];
      } else {
        return [key, val];
      }
    }),
  ) as T;
