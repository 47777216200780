import { Context } from 'chartjs-plugin-datalabels';

export const getDataLabelPosition = (context: Context, values: number[]) => {
  const middlePoint = getMiddlePoint(values);
  const index = context.dataIndex;
  const contextValue = context.dataset.data[index] as number;

  return contextValue < middlePoint ? 'top' : 'bottom';
};

const getMiddlePoint = (values: number[]) => {
  const maxValue = Math.max(...values);
  const minValue = Math.min(...values);

  return (maxValue + minValue) / 2;
};
