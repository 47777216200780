import React from 'react';

import classes from './MealTimeRow.module.scss';
import {
  GridTableCell,
  GridTableRow,
} from '../../../../shared/components/table-components';
import { processMealName } from '../../../../shared/utils/mealUtils';
import { Day, daysOfWeek } from '../../../../shared/constants/daysOfWeek';
import { MealDaysFormFieldName } from '../../../interfaces/MealTimes';
import { MealTimeCell } from '../MealTimeCell/MealTimeCell';
import { EditCell } from '../EditCell/EditCell';
import { MealName } from '../../../../shared/constants/mealNames';

export type GetIsDisabledTime = (info: { day: Day; mealName: MealName }) => boolean;

type MealTimeRowProps = {
  mealName: MealName;
  mealFormKey: 'mealTime' | 'workoutMealTime';
  getIsDisabledTime?: GetIsDisabledTime;
};

const MealTimeRow = ({
  mealName,
  mealFormKey,
  getIsDisabledTime = () => false,
}: MealTimeRowProps) => {
  return (
    <GridTableRow
      key={mealName}
      className={classes.container}
      variant="unstyled"
      dataTestId="meal-time-row"
    >
      <GridTableCell className={classes.mealTitle}>
        {processMealName(mealName)}
      </GridTableCell>
      {daysOfWeek.map((day) => {
        const fieldName = `${day}.${mealFormKey}.${mealName}` as MealDaysFormFieldName;
        const isDisabled = getIsDisabledTime({ day, mealName });

        return <MealTimeCell key={day} fieldName={fieldName} disabled={isDisabled} />;
      })}
      <EditCell mealName={mealName} mealFormKey={mealFormKey} />
    </GridTableRow>
  );
};

export { MealTimeRow };
