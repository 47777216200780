import React from 'react';

import {
  Chart as ChartJS,
  LineController,
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import classes from './CheckInHistoryChartView.module.scss';
import { InbodyScanMetrics } from '../../../../../../interfaces/customerInbodyData';
import { getFormattedDate } from '../../../../../../../shared/utils/dateUtils';
import {
  dateTimeFormat,
  localDataFormat,
} from '../../../../../../../shared/constants/apiDateFormat';
import { palette } from '../../../../../../../shared/theme/palette';
import { getDataLabelPosition } from './utils/getDataLabelPosition';
import { getShowScansData } from './utils/getShowScansData';
import { CheckInFieldsConfig } from '../CheckInHistoryTab.settings';
import { InfoBox } from '../../../../../../../shared/components/ui-components';

ChartJS.register(LineController, LineElement, PointElement, CategoryScale, LinearScale);

type CheckInHistoryChartViewProps = {
  inbodyScanMetrics: InbodyScanMetrics[];
  availableFields: CheckInFieldsConfig;
  selectedScanTime: string | null;
};

const CheckInHistoryChartView = ({
  inbodyScanMetrics,
  availableFields,
  selectedScanTime,
}: CheckInHistoryChartViewProps) => {
  const showScans = getShowScansData(inbodyScanMetrics, selectedScanTime);

  const labels = showScans.map(({ dateTimeScanOn }) =>
    getFormattedDate(dateTimeScanOn, localDataFormat, dateTimeFormat),
  );

  const pointRadius = showScans.map(({ dateTimeScanOn }) => {
    return dateTimeScanOn === selectedScanTime ? 6 : 3;
  });

  return (
    <>
      {availableFields.map((field) => {
        const values = showScans.map((dataItem) => Number(dataItem[field.dbName]));

        return (
          <InfoBox
            title={`${field.title} (${field.units})`}
            key={field.configField}
            customContentPadding
          >
            <div className={classes.chartContainer}>
              <Line
                data={{ labels, datasets: [{ data: values, pointRadius }] }}
                plugins={[ChartDataLabels]}
                options={{
                  events: [],
                  animation: false,
                  maintainAspectRatio: false,
                  elements: {
                    line: {
                      borderColor: palette.mainBlack,
                      borderWidth: 2,
                    },
                    point: {
                      backgroundColor: palette.mainBlack,
                    },
                  },
                  plugins: {
                    legend: { display: false },
                    tooltip: { enabled: false },
                    datalabels: {
                      color: palette.mainBlack,
                      font: { weight: 700 },
                      clamp: true,
                      offset: 10,
                      align: (context) => getDataLabelPosition(context, values),
                    },
                  },
                  scales: {
                    x: { offset: true, border: { dash: [3, 3] } },
                    y: {
                      offset: true,
                      display: false,
                    },
                  },
                }}
              />
            </div>
          </InfoBox>
        );
      })}
    </>
  );
};

export { CheckInHistoryChartView };
