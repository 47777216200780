import { apiService } from '../../shared/services/apiService';
import { MealPlan } from '../interfaces/MealPlan';
import { PredefinedMealPlansInfo } from '../interfaces/PredefinedMealPlansInfo';

export const mealPlanService = {
  async getPredefinedMealPlans(userId: number) {
    const resp = await apiService.get<PredefinedMealPlansInfo>(
      `/api/coach/user/${userId}/meal-plan/predefined`,
    );

    return resp.data;
  },

  async setPredefinedMealPlans(userId: number, predefinedMealPlanId: number) {
    const resp = await apiService.post<MealPlan>(
      `/api/coach/user/${userId}/meal-plan/${predefinedMealPlanId}`,
    );

    return resp.data;
  },
};
