import moment from 'moment';
import {
  dateTimeFormat,
  localDateTimeFormat,
} from '../../../../../../../shared/constants/apiDateFormat';
import { addAllOption } from '../../../../../../../shared/utils/arrayUtils';
import { getFormattedDate } from '../../../../../../../shared/utils/dateUtils';

export const getScanDateOptions = (scanDates: string[]) => {
  // sort from new to old
  const sortedScanDates = [...scanDates].sort((a, b) =>
    moment(a, dateTimeFormat) < moment(b, dateTimeFormat) ? 1 : -1,
  );

  const scanDateOptions = sortedScanDates.map((date) => ({
    label: getFormattedDate(date, localDateTimeFormat, dateTimeFormat),
    value: date,
  }));

  return addAllOption(scanDateOptions, true);
};
