import React from 'react';

import { useForm } from 'react-hook-form';

import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import { LbModal } from '../../../../../shared/components/modals';
import { LbTimePicker } from '../../../../../shared/components/form-components';
import { processMealName } from '../../../../../shared/utils/mealUtils';
import { MealName } from '../../../../../shared/constants/mealNames';

type EditMealTimeModalProps = {
  mealName: MealName;
  onSubmit: (time: string) => void;
  onClose: () => void;
};

type EditMealTimeFormValues = {
  time: string;
};

const EditMealTimeModal = ({ mealName, onSubmit, onClose }: EditMealTimeModalProps) => {
  const { control, handleSubmit } = useForm<EditMealTimeFormValues>();

  const handleEditRow = handleSubmit(({ time }) => {
    onSubmit(time);
    onClose();
  });

  return (
    <LbModal
      open
      title={`Edit all time for ${processMealName(mealName)}`}
      primaryBtnConfig={{
        text: 'Confirm',
        onClick: handleEditRow,
      }}
      secondaryBtnConfig={{
        text: 'Cancel',
        onClick: onClose,
      }}
    >
      <LbTimePicker
        control={control}
        name="time"
        rules={{
          required: 'This field is required',
        }}
        openPickerIcon={ArrowDropDownRoundedIcon}
      />
    </LbModal>
  );
};

export { EditMealTimeModal };
