import React from 'react';

import { IconButton, SvgIcon } from '@mui/material';
import { useToggle } from 'react-use';
import { useFormContext } from 'react-hook-form';

import { GridTableCell } from '../../../../shared/components/table-components';
import { ReactComponent as PenIcon } from '../../../../shared/assets/icons/pen.svg';
import { EditMealTimeModal } from './EditMealTimeModal/EditMealTimeModal';
import { MealDaysFormFieldName, MealSchedule } from '../../../interfaces/MealTimes';
import { daysOfWeek } from '../../../../shared/constants/daysOfWeek';
import { MealName } from '../../../../shared/constants/mealNames';

type EditCellProps = {
  mealName: MealName;
  mealFormKey: 'mealTime' | 'workoutMealTime';
};

const EditCell = ({ mealName, mealFormKey }: EditCellProps) => {
  const [isModalOpen, toggleModalOpen] = useToggle(false);

  const { setValue } = useFormContext<MealSchedule>();

  const handleEditRow = (time: string) => {
    daysOfWeek.forEach((day) => {
      const fieldName = `${day}.${mealFormKey}.${mealName}` as MealDaysFormFieldName;

      setValue(fieldName, time);
    });
  };

  return (
    <>
      <GridTableCell>
        <IconButton color="secondary" onClick={toggleModalOpen}>
          <SvgIcon component={PenIcon} fontSize="extraLarge" />
        </IconButton>
      </GridTableCell>
      {isModalOpen && (
        <EditMealTimeModal
          onSubmit={handleEditRow}
          mealName={mealName}
          onClose={toggleModalOpen}
        />
      )}
    </>
  );
};

export { EditCell };
