import React from 'react';
import { useFormContext } from 'react-hook-form';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import classes from './MealTimeCell.module.scss';
import { MealDaysFormFieldName, MealSchedule } from '../../../interfaces/MealTimes';
import { GridTableCell } from '../../../../shared/components/table-components';
import { LbTimePicker } from '../../../../shared/components/form-components';

type MealTimeCellProps = {
  fieldName: MealDaysFormFieldName;
  disabled?: boolean;
};

const MealTimeCell = ({ fieldName, disabled }: MealTimeCellProps) => {
  const { control } = useFormContext<MealSchedule>();

  return (
    <GridTableCell className={classes.container}>
      <LbTimePicker
        control={control}
        name={fieldName}
        disabled={disabled}
        size="small"
        rules={{
          required: 'This field is required',
        }}
        openPickerIcon={ArrowDropDownIcon}
      />
    </GridTableCell>
  );
};

export { MealTimeCell };
