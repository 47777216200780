import { InbodyScanMetrics } from '../../../../../../../interfaces/customerInbodyData';

const MAX_SCANS = 16;

export const getShowScansData = (
  inbodyScanMetrics: InbodyScanMetrics[],
  selectedScanTime: string | null,
) => {
  if (inbodyScanMetrics.length <= MAX_SCANS) {
    return inbodyScanMetrics;
  }

  if (!selectedScanTime) {
    return inbodyScanMetrics.slice(-MAX_SCANS);
  }

  const selectScanIndex = inbodyScanMetrics.findIndex(
    (scan) => scan.dateTimeScanOn === selectedScanTime,
  );

  if (selectScanIndex === -1) {
    return inbodyScanMetrics.slice(-MAX_SCANS);
  }

  // + 1 because slice exclusive end index
  const startIndex = Math.max(0, selectScanIndex + 1 - MAX_SCANS);
  const endIndex = Math.max(selectScanIndex + 1, MAX_SCANS);

  return inbodyScanMetrics.slice(startIndex, endIndex);
};
