import React, { ReactNode } from 'react';

import classes from './HeaderRow.module.scss';
import {
  GridTableCell,
  GridTableRow,
} from '../../../../shared/components/table-components';
import { Day, daysOfWeek } from '../../../../shared/constants/daysOfWeek';

type HeaderRowProps = {
  mealTitle: ReactNode;
  renderDayTitle: (day: Day) => ReactNode;
};

const HeaderRow = ({ mealTitle, renderDayTitle }: HeaderRowProps) => {
  return (
    <GridTableRow className={classes.container} variant="unstyled">
      <GridTableCell>{mealTitle}</GridTableCell>
      {daysOfWeek.map((day) => (
        <GridTableCell key={day}>{renderDayTitle(day)}</GridTableCell>
      ))}
    </GridTableRow>
  );
};

export { HeaderRow };
