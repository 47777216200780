import { InbodyDataField } from '../../../../../../shared/constants/inbody-info/inbodyData';
import { InbodyScanMetrics } from '../../../../../interfaces/customerInbodyData';

export type FieldConfig = {
  units: string | null; // null get measure unit from config
  title: string;
  dbName: keyof InbodyScanMetrics;
  configField?: InbodyDataField | null; // null if no config field
};

export type CheckInFieldsConfig = FieldConfig[];

// improve this create a function to get the config use the inbodyDataConfig like template
export const checkInFieldsConfig: CheckInFieldsConfig = [
  {
    configField: 'bmr',
    dbName: 'body_health_condition_bmr',
    units: 'kcal',
    title: 'BMR',
  },
  {
    configField: 'weight',
    dbName: 'body_comp_weight',
    title: 'Weight',
    units: 'lbs',
  },
  {
    configField: 'percentBodyFat',
    dbName: 'obesity_percentBodyFat',
    title: 'Body fat',
    units: '%',
  },
  {
    configField: 'poundOfFat',
    dbName: 'body_comp_bodyFatMass',
    title: 'Body Fat Mass',
    units: 'lbs',
  },
  {
    configField: 'lbm',
    dbName: 'body_comp_lbs',
    title: 'LBM',
    units: 'lbs',
  },
  {
    configField: 'icw',
    dbName: 'body_comp_intracellularWater',
    title: 'ICW',
    units: 'lbs',
  },
  {
    configField: 'ecw',
    dbName: 'body_comp_extracellularWater',
    title: 'ECW',
    units: 'lbs',
  },
  {
    configField: 'tbw',
    dbName: 'body_comp_bodyWater',
    title: 'TBW',
    units: 'lbs',
  },
  {
    configField: 'dlm',
    dbName: 'body_comp_dryLeanMass',
    title: 'DLM',
    units: 'lbs',
  },
  {
    configField: 'smm',
    dbName: 'muscle_fat_sceleton_mass',
    title: 'SMM',
    units: 'lbs',
  },
  {
    configField: 'chest',
    dbName: 'chest_measurement',
    title: 'Chest',
    units: null,
  },
  {
    configField: 'hip',
    dbName: 'hip_measurement',
    title: 'Hip',
    units: null,
  },
  {
    configField: 'thigh',
    dbName: 'thigh_measurement',
    title: 'Thigh',
    units: null,
  },
  {
    configField: 'navel',
    dbName: 'navel_measurement',
    title: 'Navel',
    units: null,
  },
  {
    configField: 'bicep',
    dbName: 'bicep_measurement',
    title: 'Bicep',
    units: null,
  },
];
