import React, { useEffect } from 'react';

import { angularize } from 'react-in-angularjs';
import { observer } from 'mobx-react';
import { FormProvider, useForm } from 'react-hook-form';
import { Container, Paper, Stack } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { Dictionary, keyBy } from 'lodash';
import classes from './MealTimeTab.module.scss';

import { InfoBox } from '../../../../../shared/components/ui-components';
import { TitleWithDescription } from '../../../../../shared/components/text-formation-components';
import { LbCheckbox, LbSelect } from '../../../../../shared/components/form-components';
import {
  REGULAR_MEAL_NAME,
  WORKOUT_MEAL_NAME,
} from '../../../../../shared/constants/mealNames';
import { MealSchedule } from '../../../../interfaces/MealTimes';
import { getRouteParam } from '../../../../../shared/utils/angularUtils';
import { getEnumArray } from '../../../../../shared/utils/jsUtils';
import { SystemProviders } from '../../../../../shared/providers';
import { MealTimesTable } from '../../../../components/MealTimesTable/MealTimesTable';
import { MealPlan } from '../../../../interfaces/MealPlan';
import { mealPlanService } from '../../../../services/mealPlanService';
import { Option } from '../../../../../general-types';
import { useModalToggle } from '../../../../../shared/hooks/useModalToggle';
import { SaveModal } from './SaveModal/SaveModal';
import { transformToOptions } from '../../../../../shared/utils/arrayUtils';
import { YesNoBtnRow } from '../../../../../shared/components/buttons';
import { mealScheduleService } from '../../../../services/mealScheduleService';

const NgzMealTimeTab = observer(() => {
  const [mealPlans, setMealPlans] = React.useState<Dictionary<MealPlan> | null>(null);
  const [mealPlansOptions, setMealPlansOptions] = React.useState<Option[]>([]);
  const [isSavedModalOpen, savedModalHandler] = useModalToggle();

  const customerId = getRouteParam('id');
  const formMethods = useForm<MealSchedule>({
    defaultValues: async () => mealScheduleService.getMealSchedule(customerId),
    shouldUnregister: false, // it crucial to send all data to the server, not exist data will be replaced by null
  });
  const { handleSubmit, control, watch } = formMethods;
  const mealNames = getEnumArray(REGULAR_MEAL_NAME);

  const getMealPlansInfo = async () => {
    const userMealPlans = await mealPlanService.getMealPlansMacros(customerId);

    const mealPlan = transformToOptions({
      list: userMealPlans.mealPlans,
      valueKey: 'originId',
      labelKey: 'name',
    });
    const mealPlansObject = keyBy(userMealPlans.mealPlans, 'originId');

    setMealPlans(mealPlansObject);
    setMealPlansOptions(mealPlan);
  };

  useEffect(() => {
    getMealPlansInfo();
  }, []);

  const onSubmit = async (data: MealSchedule, setImmediately: boolean) => {
    await mealScheduleService.updateMealSchedule(customerId, {
      ...data,
      setImmediately,
    });

    enqueueSnackbar('Meal schedule changes saved', { variant: 'success' });
    savedModalHandler();
  };

  const generalInputProps = { control, size: 'small' } as const;

  const WorkoutTitle = (
    <TitleWithDescription
      title="Specify workout days"
      description="Turn on days when the user has a workout."
    />
  );

  if (!mealPlans) {
    return null;
  }

  return (
    <SystemProviders>
      <Container>
        <FormProvider {...formMethods}>
          <Paper variant="roundedBox">
            <MealTimesTable
              mealNames={mealNames}
              mealFormKey="mealTime"
              mealTitle="Meals"
              renderDayTitle={(day) => (
                <Stack width="80%">
                  <LbSelect
                    {...generalInputProps}
                    name={`${day}.mealPlanOrigin`}
                    options={mealPlansOptions}
                    size="small"
                    helperText={null}
                    label={day}
                  />
                </Stack>
              )}
              getIsDisabledTime={({ day, mealName }) => {
                const mealPlanId = watch(`${day}.mealPlanOrigin`);
                const isInMealPlanOnlyTwoSnacks = mealPlans[mealPlanId]?.mealPlan === 5;
                const isSnack1 = mealName === 'snack1';

                return isInMealPlanOnlyTwoSnacks && isSnack1;
              }}
            />
          </Paper>
          <InfoBox
            title={WorkoutTitle}
            customContentPadding
            className={classes.workoutTable}
          >
            <MealTimesTable
              mealNames={Object.values(WORKOUT_MEAL_NAME)}
              mealFormKey="workoutMealTime"
              mealTitle="Pre/Post Meals"
              renderDayTitle={(day) => (
                <LbCheckbox
                  label={day}
                  color="primary"
                  control={control}
                  name={`${day}.workoutMealTime.enabled`}
                />
              )}
              getIsDisabledTime={({ day }) => {
                return !watch(`${day}.workoutMealTime.enabled`);
              }}
            />
          </InfoBox>
          <YesNoBtnRow yesText="Save" yesHandler={() => savedModalHandler()} />
          <SaveModal
            onSubmit={handleSubmit((data) => onSubmit(data, true))}
            onTomorrowSubmit={handleSubmit((data) => onSubmit(data, false))}
            isSavedModalOpen={isSavedModalOpen}
            onClose={savedModalHandler}
          />
        </FormProvider>
      </Container>
    </SystemProviders>
  );
});

angularize(
  NgzMealTimeTab,
  'ngzMealTimeTab',
  angular.module('app.customerManagement'),
  {},
);
