import { apiService } from '../../shared/services/apiService';
import { downloadFile, downloadBinaryFile } from '../../shared/utils/apiUtils';
import {
  SocialSharingTemplatesConfig,
  SocialSharingScanMetricsParams,
} from '../interfaces/socialSharingScanMetrics';

type DownloadTemplateResponse = {
  imageUrl: string;
};

export const socialSharingScanMetricsService = {
  async getTemplatesConfig() {
    const resp = await apiService.get<SocialSharingTemplatesConfig>(
      '/api/coach/scans/social-sharing/templates',
    );

    return resp.data;
  },
  // return link, when slow internet connection download the file by browser (show spinner only to get the link)
  async downloadTemplate(customerId: number, params: SocialSharingScanMetricsParams) {
    const resp = await apiService.post<DownloadTemplateResponse>(
      `/api/coach/users/${customerId}/scans/social-sharing`,
      params,
    );

    downloadFile(resp.data.imageUrl, 'scan-results.png');
  },

  // return binary file, when slow internet connection block the page (show spinner all the time)
  async downloadTemplateBin(customerId: number, params: SocialSharingScanMetricsParams) {
    const resp = await apiService.post<BlobPart>(
      `/api/coach/users/${customerId}/scans/social-sharing/download`,
      params,
      { responseType: 'blob' },
    );

    downloadBinaryFile(resp.data, 'image/png', 'scan-results');
  },
};
