import React, { ChangeEvent } from 'react';

import { Slider, TextField } from '@mui/material';
import {
  Control,
  FieldValues,
  Path,
  PathValue,
  useController,
  UseControllerProps,
} from 'react-hook-form';

import classes from './RangeInput.module.scss';
import { isInRange } from '../../../utils/mathUtils';

type RangeInputProps<T extends FieldValues, TName extends Path<T>> = {
  control: Control<T>;
  startProps: Omit<UseControllerProps<T, TName>, 'control'>;
  endProps: Omit<UseControllerProps<T, TName>, 'control'>;
  min: number;
  max: number;
};

const RangeInput = <T extends FieldValues, TName extends Path<T>>({
  control,
  startProps,
  endProps,
  min = 1,
  max = 100,
}: RangeInputProps<T, TName>) => {
  const startControllerProps = {
    ...startProps,
    defaultValue: startProps.defaultValue ?? (min as PathValue<T, TName>),
  };

  const endControllerProps = {
    ...endProps,
    defaultValue: endProps.defaultValue ?? (max as PathValue<T, TName>),
  };

  const {
    field: fieldStart,
    fieldState: { error: errorStart },
  } = useController<any>({ control, ...startControllerProps });

  const {
    field: fieldEnd,
    fieldState: { error: errorEnd },
  } = useController<any>({ control, ...endControllerProps });

  const handleChange = (event: Event, newValue: number | number[]) => {
    if (!Array.isArray(newValue)) {
      return;
    }
    const [start, end] = newValue;

    fieldStart.onChange(start);
    fieldEnd.onChange(end);
  };

  const handleInputBlur = () => {
    const start = isInRange(fieldStart.value, min, fieldEnd.value)
      ? fieldStart.value
      : min;
    const end = isInRange(fieldEnd.value, start, max) ? fieldEnd.value : max;

    fieldStart.onChange(start);
    fieldEnd.onChange(end);
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const rangePattern = /^\d{0,2}-\d{0,2}$/;

    if (!rangePattern.test(value)) {
      return;
    }

    const [start, end] = value.split('-').map(Number);

    fieldStart.onChange(start);
    fieldEnd.onChange(end);
  };

  const processInputValue = (value: number) => (value === 0 ? '' : value);

  const inputValue = `${processInputValue(fieldStart.value)}-${processInputValue(
    fieldEnd.value,
  )}`;
  const sliderValue = [fieldStart.value, fieldEnd.value];

  const error = errorStart || errorEnd;

  return (
    <div className={classes.rangeBox}>
      <TextField
        variant="outlined"
        fullWidth
        value={inputValue}
        onChange={handleInputChange}
        label="Age"
        size="small"
        onBlur={handleInputBlur}
        error={!!error}
        helperText={error?.message}
        inputRef={(el) => {
          fieldStart.ref(el);
          fieldEnd.ref(el);
        }}
      />
      <Slider
        value={sliderValue}
        onChange={handleChange}
        size="small"
        min={min}
        max={max}
        className={classes.slider}
      />
    </div>
  );
};

export { RangeInput };
