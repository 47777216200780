import { useMemo, useState } from 'react';

export const useFilterByField = <
  TItem,
  TFilterField extends keyof TItem,
  TFilterValue extends TItem[TFilterField],
>(
  data: TItem[],
  filterField: TFilterField,
) => {
  const [filterValue, setFilterValue] = useState<TFilterValue | null>(null);

  const getFilteredData = () => {
    if (!data || !filterValue) {
      return data;
    }

    return data.filter((item) => item[filterField] === filterValue);
  };

  const filteredData = useMemo(() => getFilteredData(), [data, filterValue]);

  const handleSetFilterValue = (value: TFilterValue | null) => {
    setFilterValue(value);
  };

  return { filteredData, filterValue, handleSetFilterValue };
};
