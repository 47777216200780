import React from 'react';
import classes from './SaveModal.module.scss';
import { LbModal } from '../../../../../../shared/components/modals';

type SaveModalProps = {
  onSubmit: () => void;
  onTomorrowSubmit: () => void;
  isSavedModalOpen: boolean;
  onClose: () => void;
};

export const SaveModal: React.FC<SaveModalProps> = ({
  onSubmit,
  onTomorrowSubmit,
  isSavedModalOpen,
  onClose,
}) => {
  if (!isSavedModalOpen) return null;

  return (
    <LbModal
      open={isSavedModalOpen}
      title="How would you like to change the meal schedule?"
    >
      <div className={classes.container}>
        <div className={classes.option} onClick={onSubmit}>
          <h6 className={classes.optionTitle}>Update Immediately</h6>
          <p className={classes.optionDescription}>
            Current foods entered in the App today may be erased
          </p>
        </div>
        <div className={classes.option} onClick={onTomorrowSubmit}>
          <h6 className={classes.optionTitle}>Apply Changes Tomorrow</h6>
          <p className={classes.optionDescription}>Current day will stay the same</p>
        </div>
        <div className={classes.option} onClick={onClose}>
          <h6 className={classes.optionTitle}>Cancel</h6>
          <p className={classes.optionDescription}>Do not make any changes</p>
        </div>
      </div>
    </LbModal>
  );
};
