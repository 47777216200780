import React from 'react';

import { angularize } from 'react-in-angularjs';
import { FormProvider, useForm } from 'react-hook-form';

import { SystemProviders } from '../../../../../shared/providers';
import { LbBigModal } from '../../../../../shared/components/modals';
import { SocialSharingScanMetricsParams } from '../../../../interfaces/socialSharingScanMetrics';
import { socialSharingScanMetricsService } from '../../../../services/socialSharingScanMetricsService';
import { DateRangeSection } from './DateRangeSection/DateRangeSection';
import { MetricsSection } from './MetricsSection/MetricsSection';
import { BackgroundSection } from './BackgroundSection/BackgroundSection';
import { useBgOptions } from './hooks/useBgOptions';

type NgzSocialMediaScanMetricsModalProps = {
  customerId: number;
  scanDateTimes: string[]; // "2023-04-13T11:55:06"
  onClose: () => void;
};

const NgzSocialMediaScanMetricsModal = ({
  customerId,
  scanDateTimes,
  onClose,
}: NgzSocialMediaScanMetricsModalProps) => {
  const { bgOptions } = useBgOptions();

  const formMethods = useForm<SocialSharingScanMetricsParams>();
  const {
    handleSubmit,
    watch,
    formState: { isValid },
  } = formMethods;

  const metrics = watch('metrics'); // null on initial render
  const isMetricSelected = !!metrics && Object.values(metrics).some(Boolean);

  const onSubmit = handleSubmit((data) => {
    socialSharingScanMetricsService.downloadTemplate(customerId, data);
    onClose();
  });

  return (
    <SystemProviders>
      <LbBigModal
        open={bgOptions.length > 0}
        title="Create and Download Social Media Template"
        description="Share your client's success"
        buttonOptions={{
          noText: 'Cancel',
          noHandler: onClose,
          yesText: 'Download',
          yesHandler: onSubmit,
          yesDisabled: !isMetricSelected || !isValid,
        }}
      >
        <FormProvider {...formMethods}>
          <DateRangeSection scanDateTimes={scanDateTimes} />
          <MetricsSection />
          <BackgroundSection bgOptions={bgOptions} />
        </FormProvider>
      </LbBigModal>
    </SystemProviders>
  );
};

angularize(
  NgzSocialMediaScanMetricsModal,
  'ngzSocialMediaScanMetricsModal',
  angular.module('app.inbody'),
  {
    customerId: '<',
    scanDateTimes: '<',
    onClose: '&',
  },
);

export { NgzSocialMediaScanMetricsModal };
