import React, { useMemo } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { Paper } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { observer } from 'mobx-react';

import classes from './MealTimeStep.module.scss';
import { ButtonsRow } from '../../components/ButtonsRow/ButtonsRow';
import {
  MealTimesTable,
  MealSchedule,
  mealScheduleService,
} from '../../../../../nutrition';
import { InfoBox } from '../../../../../shared/components/ui-components';
import { TitleWithDescription } from '../../../../../shared/components/text-formation-components';
import { LbCheckbox } from '../../../../../shared/components/form-components';
import {
  REGULAR_MEAL_NAME,
  WORKOUT_MEAL_NAME,
} from '../../../../../shared/constants/mealNames';
import { goTo } from '../../../../../shared/utils/angularUtils';
import { customerService } from '../../../../services/customerService';
import { getEnumArray } from '../../../../../shared/utils/jsUtils';
import { customerSetupStore } from '../../../../stores/customerSetupStore';
import { pathQueryParams } from '../../../../../shared/constants/pathQueryParams';
import { CUSTOMER_PROFILE_TAB_NAMES } from '../../../../../shared/constants/tabNames/CUSTOMER_PROFILE_TAB_NAMES';

type MealTimeStepProps = {
  customerId: number;
};

const MealTimeStep = observer(({ customerId }: MealTimeStepProps) => {
  const formMethods = useForm<MealSchedule>({
    defaultValues: async () => mealScheduleService.getMealSchedule(customerId),
    shouldUnregister: false, // it crucial to send all data to the server, not exist data will be replaced by null
  });

  const { handleSubmit, control, watch } = formMethods;

  const { mealsPerDay } = customerSetupStore;

  const onSubmit = async (data: MealSchedule) => {
    await mealScheduleService.updateMealSchedule(customerId, {
      ...data,
      setImmediately: true,
    });
    await customerService.sendSetPasswordLink(customerId);

    enqueueSnackbar(
      'The customer is set up! The email with a magic link to set a password is sent to a customer',
      { variant: 'success' },
    );

    goTo(`/customer-profile/${customerId}`, {
      params: {
        [pathQueryParams.initialTabName]: CUSTOMER_PROFILE_TAB_NAMES.CUSTOMER_PROFILE,
      },
      replaceHistory: true,
    });
  };

  const WorkoutTitle = (
    <TitleWithDescription
      title="Specify workout days"
      description="Turn on days when the user has a workout."
    />
  );

  // delete this code when add selecting meal plans for days
  const mealsList = useMemo(() => {
    switch (mealsPerDay) {
      case 5:
        return getEnumArray(REGULAR_MEAL_NAME, [REGULAR_MEAL_NAME.snack1]);
      case 6:
      default:
        return getEnumArray(REGULAR_MEAL_NAME);
    }
  }, [mealsPerDay]);

  return (
    <FormProvider {...formMethods}>
      <Paper variant="roundedBox">
        <MealTimesTable
          mealNames={mealsList}
          mealFormKey="mealTime"
          mealTitle="Meals"
          renderDayTitle={(day) => <p>{day}</p>}
        />
      </Paper>
      <InfoBox title={WorkoutTitle} customContentPadding className={classes.workoutTable}>
        <MealTimesTable
          mealNames={Object.values(WORKOUT_MEAL_NAME)}
          mealFormKey="workoutMealTime"
          mealTitle="Pre/Post Meals"
          renderDayTitle={(day) => (
            <LbCheckbox
              label={day}
              color="primary"
              control={control}
              name={`${day}.workoutMealTime.enabled`}
            />
          )}
          getIsDisabledTime={({ day }) => {
            return !watch(`${day}.workoutMealTime.enabled`);
          }}
        />
      </InfoBox>
      <ButtonsRow
        onNext={handleSubmit(onSubmit)}
        nextText="Complete user setup and Send Magic Link"
      />
    </FormProvider>
  );
});

export { MealTimeStep };
