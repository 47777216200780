import React from 'react';

import classes from './CheckInHistoryTableView.module.scss';
import {
  GridTableCell,
  GridTableRow,
} from '../../../../../../../shared/components/table-components';
import { InbodyScanMetrics } from '../../../../../../interfaces/customerInbodyData';
import { getFormattedDate } from '../../../../../../../shared/utils/dateUtils';
import {
  dateTimeFormat,
  localDataFormat,
} from '../../../../../../../shared/constants/apiDateFormat';
import { TitleWithDescription } from '../../../../../../../shared/components/text-formation-components';
import { CheckInFieldsConfig } from '../CheckInHistoryTab.settings';
import { useSort } from '../../../../../../../shared/hooks/useSort';
import { SortButtons } from '../../../../../../../shared/components/buttons';

type CheckInHistoryTableViewProps = {
  userInbodyMetricsDataByDate: InbodyScanMetrics[];
  availableFields: CheckInFieldsConfig;
};

const CheckInHistoryTableView = ({
  userInbodyMetricsDataByDate,
  availableFields,
}: CheckInHistoryTableViewProps) => {
  const fieldsCount = availableFields.length;

  const { sortedData: sortedInbodyMetrics, sortMethods } = useSort({
    data: userInbodyMetricsDataByDate,
    dateFormat: dateTimeFormat,
    sortTypeConfig: { dateTimeScanOn: 'DATE' },
    defaultSortInfo: { fieldName: 'dateTimeScanOn', direction: 'DESC' },
  });

  return (
    <div
      className={classes.checkInHistoryTable}
      style={{
        display: 'grid',
        gridTemplateColumns: `120px repeat(${fieldsCount}, minmax(max-content, 1fr))`,
      }}
    >
      <GridTableRow variant="header" className={classes.header}>
        <GridTableCell>
          <SortButtons
            fieldName="dateTimeScanOn"
            onSort={sortMethods.handleSort}
            getIsActive={sortMethods.getIsSortedBy}
          >
            Date
          </SortButtons>
        </GridTableCell>
        {availableFields.map((field) => (
          <GridTableCell key={field.title}>
            <TitleWithDescription
              title={field.title}
              description={field.units}
              titleFont="body-14-bold"
              withoutGap
            />
          </GridTableCell>
        ))}
      </GridTableRow>
      {sortedInbodyMetrics?.map((dataItem) => (
        <GridTableRow key={dataItem.dateTimeScanOn} variant="row">
          <GridTableCell variant="title">
            {getFormattedDate(dataItem.dateTimeScanOn, localDataFormat, dateTimeFormat)}
          </GridTableCell>
          {availableFields.map((field) => (
            <GridTableCell key={field.dbName}>{dataItem[field.dbName]}</GridTableCell>
          ))}
        </GridTableRow>
      ))}
    </div>
  );
};

export { CheckInHistoryTableView };
