import {
  InbodyMeasureUnit,
  humanizeMeasureUnit,
  humanizeMeasureUnitShort,
} from '../../../constants/inbody-info/inbodyMeasureUnit';

type Variant = 'short' | 'long';

export const getHumanizeMeasureUnit = (
  measureUnit: InbodyMeasureUnit,
  variant: Variant,
) => {
  switch (variant) {
    case 'short':
      return humanizeMeasureUnitShort[measureUnit] || measureUnit;
    default:
      return humanizeMeasureUnit[measureUnit] || measureUnit;
  }
};
