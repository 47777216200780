import React, { ReactNode } from 'react';

import classes from './MealTimesTable.module.scss';
import { HeaderRow } from './HeaderRow/HeaderRow';
import { Day } from '../../../shared/constants/daysOfWeek';
import { MealName } from '../../../shared/constants/mealNames';
import { GetIsDisabledTime, MealTimeRow } from './MealTimeRow/MealTimeRow';

type MealTimesTableProps = {
  mealNames: MealName[];
  mealFormKey: 'mealTime' | 'workoutMealTime';
  mealTitle: ReactNode;
  renderDayTitle: (day: Day) => ReactNode;
  getIsDisabledTime?: GetIsDisabledTime;
};

const MealTimesTable = ({
  mealNames,
  mealFormKey,
  mealTitle,
  renderDayTitle,
  getIsDisabledTime,
}: MealTimesTableProps) => {
  return (
    <div className={classes.container}>
      <HeaderRow mealTitle={mealTitle} renderDayTitle={renderDayTitle} />
      <div className={classes.rows}>
        {mealNames.map((mealName) => (
          <MealTimeRow
            key={mealName}
            mealName={mealName}
            mealFormKey={mealFormKey}
            getIsDisabledTime={getIsDisabledTime}
          />
        ))}
      </div>
    </div>
  );
};

export { MealTimesTable };
