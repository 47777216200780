import { useMemo } from 'react';

import {
  getFormattedDate,
  isDataBefore,
  isDayAfter,
} from '../../../shared/utils/dateUtils';
import {
  dateTimeFormat,
  localDateTimeFormat,
} from '../../../shared/constants/apiDateFormat';

export type UseScanDataRangeOptionsReturn = {
  startScanDateOptions: string[];
  endScanDateOptions: string[];
  getOptionLabel: (option: string) => string;
};

export type UseScanDataRangeOptionsProps = {
  scanDateTimes: string[];
  startScanDate: string;
  endScanDate: string;
};

export const useScanDataRangeOptions = ({
  scanDateTimes,
  startScanDate,
  endScanDate,
}: UseScanDataRangeOptionsProps): UseScanDataRangeOptionsReturn => {
  const timeOptions = useMemo(
    () => [...scanDateTimes].sort((a, b) => (isDayAfter(a, b) ? -1 : 1)),
    [scanDateTimes],
  );

  const startScanDateOptions = useMemo(() => {
    if (!endScanDate) {
      return timeOptions.slice(1);
    }

    return timeOptions.filter((date) => isDataBefore(date, endScanDate));
  }, [timeOptions, endScanDate]);

  const endScanDateOptions = useMemo(() => {
    if (!startScanDate) {
      return timeOptions;
    }
    return timeOptions.filter((date) => isDayAfter(date, startScanDate));
  }, [timeOptions, startScanDate]);

  const getOptionLabel = (option: string) =>
    getFormattedDate(option, localDateTimeFormat, dateTimeFormat);

  return { startScanDateOptions, endScanDateOptions, getOptionLabel };
};
