import { Option } from '../../../../../../general-types';
import { SocialSharingMetrics } from '../../../../../interfaces/socialSharingScanMetrics';

type MetricsOptions = Option<SocialSharingMetrics>[];

export const metricsOptions: MetricsOptions = [
  {
    label: 'Weight (lbs)',
    value: 'weight',
  },
  {
    label: 'Body Fat Mass (lbs)',
    value: 'poundOfFat',
  },
  {
    label: 'Skeletal Muscle Mass (lbs)',
    value: 'smm',
  },
  {
    label: 'Percent Body Fat (%)',
    value: 'percentBodyFat',
  },
  {
    label: 'Lean Body Mass (lbs)',
    value: 'lbm',
  },
];
